.nounderline {
  text-decoration: none !important;
  font-weight: bold !important;
}
.navLink {
  font-size: 19px !important;
  color: #01389e !important;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none !important;

  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.sliderText {
  padding-top: -300px !important;
}
.footer-text {
  text-transform: capitalize !important;
}
